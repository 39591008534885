import './Popular.scss';
import star1 from '../../assets/images/star1.png';
import star2 from '../../assets/images/star2.png';
import empty from '../../assets/images/res370-232.png';
import {Carousel} from '../Carousel/Carousel';
import { CarouselItem } from '../Carousel/Carousel';



function Popular() {
    return (
        <div id="popular_cruises1">
            <div className="container">

                <h2 className="animate__animated">POPULAR CRUISES</h2>

                <div className="subtitle animate__animated">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                    euismod <br />tincidunt ut laoreet dolore magna aliquam erat volutpat.
                </div>

                <br /><br />



                <div id="popular_wrapper" className="animate__animated animate__flipInY" data-animation="fadeIn" data-animation-delay="300">
                    <div id="popular_inner">
                        <div className="">
                            <div id="popular">
                                <div className="container-fluid text-center my-3">
                                    <div className="row">
                                        <div className="row mx-auto my-auto justify-content-center">
                                            <Carousel id="popular_items" slidesPerPage={3}>
                                                <CarouselItem active>
                                                    <div className="">
                                                        <div className="popular">
                                                            <div className="popular_inner">
                                                                <figure>
                                                                    <img src={empty} alt=""
                                                                        className="img-responsive" />
                                                                    <div className="over">
                                                                        <div className="v1">Bahamas <span>17 Deal Offers</span>
                                                                        </div>
                                                                        <div className="v2">Lorem ipsum dolor sit amet,
                                                                            concateus.
                                                                        </div>
                                                                    </div>
                                                                </figure>
                                                                <div className="caption">
                                                                    <div className="txt1"><span>Bahamas</span> 7 Night Tour
                                                                    </div>
                                                                    <div className="txt2">Nam liber tempor cum soluta nobis
                                                                        eleifend option congue nihil imperdiet doming.
                                                                    </div>
                                                                    <div className="txt3 clearfix">
                                                                        <div className="left_side">
                                                                            <div className="stars1">
                                                                                <img src={star1} alt="" />
                                                                                <img src={star1} alt="" />
                                                                                <img src={star1} alt="" />
                                                                                <img src={star1} alt="" />
                                                                                <img src={star2} alt="" />
                                                                            </div>
                                                                            <div className="nums">- 18 Reviews</div>
                                                                        </div>
                                                                        <div className="right_side"><a href="#"
                                                                            className="btn-default btn1">See
                                                                            All</a></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CarouselItem>
                                                <CarouselItem>
                                                    <div className="">
                                                        <div className="popular">
                                                            <div className="popular_inner">
                                                                <figure>
                                                                    <img src={empty} alt=""
                                                                        className="img-responsive" />
                                                                    <div className="over">
                                                                        <div className="v1">Mediterranean
                                                                            <span>17 Deal Offers</span></div>
                                                                        <div className="v2">Lorem ipsum dolor sit amet,
                                                                            concateus.
                                                                        </div>
                                                                    </div>
                                                                </figure>
                                                                <div className="caption">
                                                                    <div className="txt1"><span>Mediterranean</span> 18 Night
                                                                        Tour
                                                                    </div>
                                                                    <div className="txt2">Nam liber tempor cum soluta nobis
                                                                        eleifend option congue nihil imperdiet doming.
                                                                    </div>
                                                                    <div className="txt3 clearfix">
                                                                        <div className="left_side">
                                                                            <div className="stars1">
                                                                                <img src={star1} alt="" />
                                                                                <img src={star1} alt="" />
                                                                                <img src={star1} alt="" />
                                                                                <img src={star1} alt="" />
                                                                                <img src={star1} alt="" />
                                                                            </div>
                                                                            <div className="nums">- 168 Reviews</div>
                                                                        </div>
                                                                        <div className="right_side"><a href="#"
                                                                            className="btn-default btn1">See
                                                                            All</a></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </CarouselItem>
                                                <CarouselItem>
                                                    <div className="">
                                                        <div className="popular">
                                                            <div className="popular_inner">
                                                                <figure>
                                                                    <img src={empty} alt=""
                                                                        className="img-responsive" />
                                                                    <div className="over">
                                                                        <div className="v1">Greece <span>17 Deal Offers</span>
                                                                        </div>
                                                                        <div className="v2">Lorem ipsum dolor sit amet,
                                                                            concateus.
                                                                        </div>
                                                                    </div>
                                                                </figure>
                                                                <div className="caption">
                                                                    <div className="txt1"><span>Greece</span> 6 Night Tour</div>
                                                                    <div className="txt2">Nam liber tempor cum soluta nobis
                                                                        eleifend option congue nihil imperdiet doming.
                                                                    </div>
                                                                    <div className="txt3 clearfix">
                                                                        <div className="left_side">
                                                                            <div className="stars1">
                                                                                <img src={star1} alt="" />
                                                                                <img src={star1} alt="" />
                                                                                <img src={star1} alt="" />
                                                                                <img src={star1} alt="" />
                                                                                <img src={star2} alt="" />
                                                                            </div>
                                                                            <div className="nums">- 16 Reviews</div>
                                                                        </div>
                                                                        <div className="right_side"><a href="#"
                                                                            className="btn-default btn1">See
                                                                            All</a></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </CarouselItem>
                                                <CarouselItem>
                                                    <div className="">

                                                        <div className="popular">
                                                            <div className="popular_inner">
                                                                <figure>
                                                                    <img src={empty} alt=""
                                                                        className="img-responsive" />
                                                                    <div className="over">
                                                                        <div className="v1">Bahamas <span>17 Deal Offers</span>
                                                                        </div>
                                                                        <div className="v2">Lorem ipsum dolor sit amet,
                                                                            concateus.
                                                                        </div>
                                                                    </div>
                                                                </figure>
                                                                <div className="caption">
                                                                    <div className="txt1"><span>Bahamas</span> 7 Night Tour
                                                                    </div>
                                                                    <div className="txt2">Nam liber tempor cum soluta nobis
                                                                        eleifend option congue nihil imperdiet doming.
                                                                    </div>
                                                                    <div className="txt3 clearfix">
                                                                        <div className="left_side">
                                                                            <div className="stars1">
                                                                                <img src={star1} alt="" />
                                                                                <img src={star1} alt="" />
                                                                                <img src={star1} alt="" />
                                                                                <img src={star1} alt="" />
                                                                                <img src={star2} alt="" />
                                                                            </div>
                                                                            <div className="nums">- 18 Reviews</div>
                                                                        </div>
                                                                        <div className="right_side"><a href="#"
                                                                            className="btn-default btn1">See
                                                                            All</a></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CarouselItem>
                                                <CarouselItem>
                                                    <div className="">

                                                        <div className="popular">
                                                            <div className="popular_inner">
                                                                <figure>
                                                                    <img src={empty} alt=""
                                                                        className="img-responsive" />
                                                                    <div className="over">
                                                                        <div className="v1">Mediterranean
                                                                            <span>17 Deal Offers</span></div>
                                                                        <div className="v2">Lorem ipsum dolor sit amet,
                                                                            concateus.
                                                                        </div>
                                                                    </div>
                                                                </figure>
                                                                <div className="caption">
                                                                    <div className="txt1"><span>Mediterranean</span> 18 Night
                                                                        Tour
                                                                    </div>
                                                                    <div className="txt2">Nam liber tempor cum soluta nobis
                                                                        eleifend option congue nihil imperdiet doming.
                                                                    </div>
                                                                    <div className="txt3 clearfix">
                                                                        <div className="left_side">
                                                                            <div className="stars1">
                                                                                <img src={star1} alt="" />
                                                                                <img src={star1} alt="" />
                                                                                <img src={star1} alt="" />
                                                                                <img src={star1} alt="" />
                                                                                <img src={star1} alt="" />
                                                                            </div>
                                                                            <div className="nums">- 168 Reviews</div>
                                                                        </div>
                                                                        <div className="right_side"><a href="#"
                                                                            className="btn-default btn1">See
                                                                            All</a></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CarouselItem>
                                                <CarouselItem>
                                                    <div className="">
                                                        <div className="popular">
                                                            <div className="popular_inner">
                                                                <figure>
                                                                    <img src={empty} alt=""
                                                                        className="img-responsive" />
                                                                    <div className="over">
                                                                        <div className="v1">Greece <span>17 Deal Offers</span>
                                                                        </div>
                                                                        <div className="v2">Lorem ipsum dolor sit amet,
                                                                            concateus.
                                                                        </div>
                                                                    </div>
                                                                </figure>
                                                                <div className="caption">
                                                                    <div className="txt1"><span>Greece</span> 6 Night Tour</div>
                                                                    <div className="txt2">Nam liber tempor cum soluta nobis
                                                                        eleifend option congue nihil imperdiet doming.
                                                                    </div>
                                                                    <div className="txt3 clearfix">
                                                                        <div className="left_side">
                                                                            <div className="stars1">
                                                                                <img src={star1} alt="" />
                                                                                <img src={star1} alt="" />
                                                                                <img src={star1} alt="" />
                                                                                <img src={star1} alt="" />
                                                                                <img src={star2} alt="" />
                                                                            </div>
                                                                            <div className="nums">- 16 Reviews</div>
                                                                        </div>
                                                                        <div className="right_side"><a href="#"
                                                                            className="btn-default btn1">See
                                                                            All</a></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </CarouselItem>
                                            </Carousel>
                                         
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>


    );
}

export default Popular;
