import './Copyright.scss';

function Copyright() {
    return (
        <div className="copyright-wrapper">
            <div className="container">
                <div className="left-side">
                    Copyright © 2017 <strong>MECOVACHE</strong> <span>|</span> <a href="#">Privacy Policy</a> <span>|</span>
                    <a href="#">About Us</a> <span>|</span> <a href="#">FAQ</a> <span>|</span> <a href="#">Contact
                        Support</a>
                </div>
                <div className="right-side">Designed by <strong>MECOVACHE</strong></div>
            </div>
        </div>
    );
}

export default Copyright;
