import './TopBanner.scss';

function TopBanner() {
    return (
        <div className="top_banner">
            <div className="container">
                <div className="top1 clearfix">
                    <div className="email">
                        <a href="#">
                            <i className="fa fa-envelope"></i>
                            support@travelagency.com
                        </a>
                    </div>
                    <div className="phone">
                        <i className="fa fa-phone"></i>
                        +917 3386831
                    </div>
                    <div className="social_wrapper">
                        <ul className="social clearfix">
                            <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                            <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                            <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                            <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                            <li><a href="#"><i className="fa fa-vimeo-square"></i></a></li>
                        </ul>
                    </div>
                    <div className="lang">
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                English
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a className="dropdown-item ge" href="#">German</a></li>
                                <li><a className="dropdown-item ru" href="#">Russian</a></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopBanner;
