import './Carousel.scss';
import React, { Children, useState } from 'react';
import PropTypes, { number } from 'prop-types';

interface CarouselItemProps {
    // add any custom props, but don't have to specify `children`
    children: React.ReactNode,
    active?: boolean,
}


export const CarouselItem: React.FC<React.PropsWithChildren<CarouselItemProps>> = ({ children, active }) => {
    return (
        <div className={active ? 'carousel-item active' : 'carousel-item'}   >
            {children}
        </div>
    )
}

interface CarouselProps {
    children?: React.ReactNode,
    interval?: number,
    index?: number,
    id?: string,
    slidesPerPage?: number
}



export const Carousel: React.FC<React.PropsWithChildren<CarouselProps>> = ({ children, interval, id,
    slidesPerPage
 }
) => {

    const style = { "--items": slidesPerPage } as React.CSSProperties;

    return (
        <div
            id={id}
            className="carousel carousel-dark slide"
            data-bs-touch="false"
            data-bs-ride="carousel"
            data-bs-interval="3000"
            style={style}
        >
            <div className="carousel-indicators">

{
    Children.map(children, (child, index) =>
        <button type="button" data-bs-target={'#' + id} data-bs-slide-to={index} className={index === 0 ? 'active' : ''} aria-current={index === 0} aria-label={'Slide ' + index}></button>
      )
}
            </div>
            <div className="carousel-inner" role="listbox" >
                {Children.map(children, child =>

                    child

                )}
            </div>
        </div>


    );
}

// export default Carousel;
