import { Carousel, CarouselItem } from '../Carousel/Carousel';
import './Home.scss';
import React from "react";

function Home() {
    return (
        <div id="slider_wrapper">
            <div className="container">
                <div id="slider_inner">

                    <Carousel id="ads" slidesPerPage={1}>
                        <CarouselItem active>
                            <div className="slider d-block w-100">
                                <div className="slider_inner">
                                    <div className="txt1"><span>Welcome To Our</span></div>
                                    <div className="txt2"><span>TRAVEL AGENCY</span></div>
                                    <div className="txt3"><span>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod.</span>
                                    </div>
                                </div>
                            </div>
                        </CarouselItem>

                        <CarouselItem>
                            <div className="slider d-block w-100">
                                <div className="slider_inner">
                                    <div className="txt1"><span>7 - Day Tour</span></div>
                                    <div className="txt2"><span>AMAZING CARIBBEAN</span></div>
                                    <div className="txt3"><span>Lorem ipsum dolor eleifend option congue nihil imperdiet doming id quod.</span>
                                    </div>
                                </div>
                            </div>
                        </CarouselItem>

                        <CarouselItem>
                            <div className="slider d-block w-100">
                                <div className="slider_inner">
                                    <div className="txt1"><span>5 Days In</span></div>
                                    <div className="txt2"><span>PARIS (Capital Of World)</span>
                                    </div>
                                    <div className="txt3"><span>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod.</span>
                                    </div>
                                </div>
                            </div>
                        </CarouselItem>

                        <CarouselItem>
                            <div className="slider d-block w-100">
                                <div className="slider_inner">
                                    <div className="txt1"><span>12 - Day Cruises</span></div>
                                    <div className="txt2"><span>FROM GREECE TO SPAIN</span>
                                    </div>
                                    <div className="txt3"><span>MEDITERRANEAN - 12 - Day Cruises By "GRAND VICTORIA III" Cruise Liner.</span>
                                    </div>

                                </div>
                            </div>
                        </CarouselItem>
                    </Carousel>


                </div>
            </div>
        </div>
    );
}

export default Home;
