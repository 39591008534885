import './Offer.scss';
import React from "react";


function Offer() {
    return (
        <div id="parallax1" className="parallax">
        <div className="bg1 parallax-bg"></div>
        <div className="overlay"></div>
        <div className="parallax-content">
            <div className="container">

                <div className="row">
                    <div className="col-sm-10 animate__animated">
                        <div className="txt1">Caucasus Vacation Packages</div>
                        <div className="txt2">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
                            nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim
                            veniam, quis nostrud exerci tation ullamcorper.
                        </div>
                        <div className="txt3">From: Khazbegi (Goergia) <strong>$159.00</strong><span>person</span></div>
                    </div>
                    <div className="col-sm-2">
                        <a href="#" className="btn-default btn-danger">Details</a>
                    </div>
                </div>

            </div>
        </div>
    </div>


    );
}

export default Offer;
