import './MenuBar.scss';
import logo from '../../assets/images/logo.png'

function MenuBar() {
    return (
        <div className="menu-bar">
            <div className="container">
                <div className="menu-bar-content clearfix">
                    <header>
                        <div className="logo_wrapper">
                            <a href="index.html" className="logo">
                                <img src={logo} alt="" className="img-responsive" />
                            </a>
                        </div>
                    </header>
                    <div className="navbar menu-bar-navbar navbar-expand-lg navbar-light navbar_">
                        
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        
                        <div className="collapse navbar-collapse navbar-collapse_" id="navbarSupportedContent">
                            <ul className="nav navbar-nav sf-menu clearfix">
                                <li className="nav-item"><a className="nav-link active" href="index.html">Home</a></li>
                                <li className="nav-item"><a className="nav-link" href="about.html">About Us</a></li>
                                <li className="nav-item"><a className="nav-link" href="contacts.html">Contacts</a></li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default MenuBar;
