import './NavigateToTop.scss';

function NavigateToTop() {
    return (
        <a href="#" id="toTop" style={{ "display": "inline" }}>
        </a>
    );
}

export default NavigateToTop;
