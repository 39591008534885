import './Footer.scss';
import logo2 from '../../assets/images/logo2.png'

function Footer() {
    return (
        <div className="footer_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-sm-3">
                        <div className="logo_wrapper">
                            <a href="index.html" className="logo2">
                            <img src={logo2} alt="" className="img-responsive" />
                            </a>
                        </div>
                        <p>
                            Nam liber tempor cum soluta nobis option congue nihil imperdiet doming id quod mazim. Lorem
                            ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                            laoreet dolore magna.
                        </p>
                        <p>
                            <a href="#" className="btn-default btn2">Read More</a>
                        </p>
                    </div>
                    <div className="col-sm-3">
                        <div className="title">Travel Specialists</div>
                        <ul className="link-list">
                            <li><a href="#">First Class Flights</a></li>
                            <li><a href="#">Accessible Travel</a></li>
                            <li><a href="#">Amazing Cruises</a></li>
                        </ul>

                        <div className="social_wrapper">
                            <ul className="social clearfix">
                                <li className="nav1"><a href="#"></a></li>
                                <li className="nav2"><a href="#"></a></li>
                                <li className="nav3"><a href="#"></a></li>
                                <li className="nav4"><a href="#"></a></li>
                                <li className="nav5"><a href="#"></a></li>
                                <li className="nav6"><a href="#"></a></li>
                            </ul>
                        </div>

                    </div>
                    <div className="col-sm-3">
                        <div className="title">Our Twitter</div>
                        <div className="twits">
                            <div className="twit">
                                <a href="#"> @travel</a> Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                                <div className="date">5 minutes ago</div>
                            </div>
                            <div className="twit">
                                <a href="#">@leo</a> Nam liber tempor cum soluta nobis option congue nihil imperdiet doming
                                id quod mazim.
                                <div className="date">2 days ago</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="title">Newsletter</div>
                        <div className="newsletter_block">
                            <div className="txt1">Inspiration, ideas, news and your feedback.</div>
                            <div className="newsletter-wrapper clearfix">
                                <form className="newsletter" action="javascript:;">
                                    <input type="text" name="s" value='Email Address' />
                                    {/*onBlur="if(this.value=='') this.value='Email Address'"
                                        onFocus="if(this.value =='Email Address' ) this.value=''" */}

                                    <a href="#" className="btn-default btn-danger">SUBMIT</a>
                                </form>
                            </div>
                        </div>
                        <div className="phone">1-917-338-6831</div>
                        <div className="support"><a href="#">support@templates-support.com</a></div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Footer;
