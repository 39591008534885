import './Why.scss';
import React from "react";

import why1 from '../../assets/images/why1.png'
import why1_hover from '../../assets/images/why1_hover.png'
import why2 from '../../assets/images/why2.png'
import why2_hover from '../../assets/images/why2_hover.png'
import why3 from '../../assets/images/why3.png'
import why3_hover from '../../assets/images/why3_hover.png'
import why4 from '../../assets/images/why4.png'
import why4_hover from '../../assets/images/why4_hover.png'

function Why() {
    return (
        <div id="why1">
            <div className="container">

                <h2 className="animate__animated animate__flipInY ">WHY WE ARE THE BEST</h2>

                <div className="subtitle animate__animated">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                    euismod <br />tincidunt ut laoreet dolore magna aliquam erat volutpat.
                </div>

                <br/><br/>

                <div className="row">
                    <div className="col-sm-3">
                        <div className="thumb2 animate__animated animate__flipInY" data-animation="flipInY" data-animation-delay="200">
                            <div className="thumbnail clearfix">
                                <a href="#">
                                    <figure className="">
                                        <img src={why1} alt="" className="img1 img-responsive" />
                                        <img src={why1_hover} alt="" className="img2 img-responsive" />
                                    </figure>
                                    <div className="caption">
                                        <div className="txt1">Amazing Travel</div>
                                        <div className="txt2">Nam liber tempor cum soluta nobis eleifend option congue nihil
                                            imperdiet doming id quod mazim.
                                        </div>
                                        <div className="txt3">Read More</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="thumb2 animate__animated animate__flipInY" data-animation="flipInY" data-animation-delay="300">
                            <div className="thumbnail clearfix">
                                <a href="#">
                                    <figure className="">
                                        <img src={why2} alt="" className="img1 img-responsive" />
                                        <img src={why2_hover} alt="" className="img2 img-responsive" />
                                    </figure>
                                    <div className="caption">
                                        <div className="txt1">Discover</div>
                                        <div className="txt2">Nam liber tempor cum soluta nobis eleifend option congue nihil
                                            imperdiet doming id quod mazim.
                                        </div>
                                        <div className="txt3">Read More</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="thumb2 animate__animated animate__flipInY" data-animation="flipInY" data-animation-delay="400">
                            <div className="thumbnail clearfix">
                                <a href="#">
                                    <figure className="">
                                    <img src={why3} alt="" className="img1 img-responsive" />
                                    <img src={why3_hover} alt="" className="img2 img-responsive" />
                                    </figure>
                                    <div className="caption">
                                        <div className="txt1">Book Your Trip</div>
                                        <div className="txt2">Nam liber tempor cum soluta nobis eleifend option congue nihil
                                            imperdiet doming id quod mazim.
                                        </div>
                                        <div className="txt3">Read More</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="thumb2 animate__animated animate__flipInY" data-animation="flipInY" data-animation-delay="500">
                            <div className="thumbnail clearfix">
                                <a href="#">
                                    <figure className="">
                                    <img src={why4} alt="" className="img1 img-responsive" />
                                    <img src={why4_hover} alt="" className="img2 img-responsive" />
                                    </figure>
                                    <div className="caption">
                                        <div className="txt1">Nice Support</div>
                                        <div className="txt2">Nam liber tempor cum soluta nobis eleifend option congue nihil
                                            imperdiet doming id quod mazim.
                                        </div>
                                        <div className="txt3">Read More</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>

    );
}

export default Why;
