import './App.css';
import TopBanner from './top-banner/TopBanner';
import MenuBar from './menu-bar/MenuBar';
import Footer from './footer/Footer';
import Copyright from './copyright/Copyright';
import NavigateToTop from './navigate-to-top/NavigateToTop';
import Home from './home/Home';
import Why from './why/Why';
import Offer from './offer/Offer';
import Popular from './popular/Popular';




function App() {
  return (
    <body className="front">

    <div id="main">
      <TopBanner></TopBanner>
      <MenuBar></MenuBar>
      <Home></Home>
      <Why></Why>
      <Offer></Offer>
      <Popular></Popular>
      <Footer></Footer>
      <Copyright></Copyright>
      <NavigateToTop></NavigateToTop>
    </div>

    </body>
  );
}

export default App;
